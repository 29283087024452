body {
  font-family: 'verdana' !important;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #0A1E21;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@media screen and (min-height: 500px ) {
  .App-header {
    background-image: url('./assets/wave.png') !important;
    background-position: bottom;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    /* background-size: contain !important; */
  }
}

@media screen and (min-width: 1000px) {
  .App-header {
    background-size: contain !important;
  }
}

@media screen and (min-width: 1250px) {
  .wrapper {
    margin-top: -150px;
  }
}

input {
  min-height: 43px !important;
  min-width: 200px !important;
  background-color: transparent !important;
  border: none !important;
  color: #FFFFFF !important;
}

.input-wrappper {
  border-bottom: 2px solid #FFFFFF;
  padding: 1px !important;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  min-width: 120px !important;
  border: none !important;
}

.btn-outline-secondary {
  background-color: #0A1E21 !important;
  color: #FFFFFF !important;
}

.sub-title {
  font-family: 'verdana';
}

.font-rock-well {
  font-family: 'rock-well';
}

/* body {
  font-family: 'verdana' !important;
  background-image: url('./assets//wave.png') !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}
.App {
  text-align: center;
  background-color: #0A1E21;
  min-height: 100vh !important;
  background-image: url('./assets//wave.png') !important;
  background-position: bottom;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
}

@media screen and (min-width: 1000px) {
  .App {
    background-size: contain !important;
  }
}

@media screen and (max-height: 650px) {
  .App {
    background-image: none !important;
  }
  .header {
    padding-top: 150px !important;
  }
}

@media screen and (max-width: 800px) and (max-height: 400px) {
  .header {
    padding-top: 0px !important;
  }
}

.header {
  padding-top: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.font-rock-well {
  font-family: 'rock-well';
}

input {
  min-height: 43px !important;
  min-width: 200px !important;
  background-color: transparent !important;
  border: none !important;
  color: #FFFFFF !important;
}

.input-wrappper {
  border-bottom: 2px solid #FFFFFF;
  padding: 1px !important;
}

.form-control:focus {
  box-shadow: none;
}

.btn {
  min-width: 120px !important;
  border: none !important;
}

.btn-outline-secondary {
  background-color: #0A1E21 !important;
  color: #FFFFFF !important;
}

@media screen and (min-width: 1200px) {
  .header {
    padding-top: 120px !important;
  }
}

.sub-title {
  font-family: 'verdana';
  margin-top: -10px;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 250px !important;
} */

@font-face {
  font-family: "rock-well";
  font-style: normal;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
  src: url("./font/ROCK.TTF");
}

@font-face {
  font-family: "rock-well-bold";
  font-style: normal;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
  src: url("./font/rockb.ttf");
}

@font-face {
  font-family: "verdana";
  font-style: normal;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
  src: url("./font/verdana.ttf");
}